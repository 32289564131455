<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="success" @click="createActivity">创建活动</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #action>
        <el-table-column label="操作" width="430" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="editItem(scoped.row)"
            >
              修改
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="lookItem(scoped.row)"
            >
              查看封面图
            </el-button>
            <el-button type="primary" size="small" @click="setItem(scoped.row)">
              设置封面图
            </el-button>
            <el-button
              v-if="scoped.row.enable"
              type="primary"
              size="small"
              @click="takeItem(scoped.row)"
            >
              下架活动
            </el-button>
            <el-button
              v-if="!scoped.row.enable"
              type="primary"
              @click="putItem(scoped.row)"
              size="small"
            >
              上架活动
            </el-button>
            <el-button
              type="primary"
              @click="deleteItem(scoped.row)"
              size="small"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="createEventVisible"
      :title="titleVisible"
      width="50%"
      :before-close="close"
    >
      <el-form
        ref="dialogForm"
        class="dialogForm"
        :model="form"
        :rules="formRules"
        label-width="140px"
      >
        <div v-if="setImgShow">
          <el-form-item label="活动名称(标题)" prop="title">
            <el-input v-model="form.title" clearable placeholder="请输入" />
          </el-form-item>
          <el-form-item label="副标题" prop="subtitle">
            <el-input v-model="form.subtitle" clearable placeholder="请输入" />
          </el-form-item>
          <el-form-item label="活动时间" prop="time">
            <el-date-picker
              v-model="form.time"
              type="daterange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              range-separator="至"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              clearable
            />
          </el-form-item>
          <el-form-item label="活动类型" prop="type">
            <el-select
              v-model="form.type"
              placeholder="请选择"
              @change="handleChangeSort"
            >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="活动状态" prop="activeStatus">
            <el-select v-model="form.activeStatus" placeholder="请选择">
              <el-option
                v-for="item in activeStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上下架状态" prop="shelvesStatus">
            <el-select v-model="form.shelvesStatus" placeholder="请选择">
              <el-option
                v-for="item in shelvesStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="排序" class="box-main">
            <el-input
              v-model="form.sort"
              @input="handleInput"
              clearable
              placeholder="请输入"
            />
            <el-tooltip
              class="box-item"
              effect="dark"
              content="数字越大排序越靠前"
              placement="top"
            >
              <I name="Warning"></I>
            </el-tooltip>
          </el-form-item>
        </div>
        <el-form-item class="other-form-item" label="封面图">
          <el-upload
            class="upload-demo"
            drag
            :limit="1"
            :action="UploadUrl()"
            :before-upload="beforeUploadFile"
            :on-change="fileChange"
            :on-exceed="exceedFile"
            :on-remove="handleRemove"
            :file-list="fileList"
            :http-request="uploadFile"
            :disabled="fileDisabled"
            v-loading="loadingDialog"
          >
            <img style="width: 100%" v-if="fileObj.url" :src="fileObj.url" />
            <el-icon v-if="!fileObj.url" class="el-icon--upload">
              <!-- <upload-filled /> -->
              <I name="UploadFilled"></I>
            </el-icon>
            <div v-if="!fileObj.url" class="el-upload__text">
              将文件拖拽此处，或<em>点击上传</em>
            </div>
            <template #tip>
              <div class="el-upload__tip">请上传xx:xx比例图片</div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="activeSubmit(dialogForm)">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="preview" width="80%">
      <img
        w-full
        :src="dialogImageUrl"
        style="display: block; margin: 0 auto"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs, ref } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  activityCreate,
  activityQuery,
  activityUpdate,
  activityEnable,
  activityDelete,
  activityPicture,
  activitySort,
  putObject,
  ossObject
} from '@/api/eventManagement'
import { constantQuery } from '@/api/user'
const dialogForm = ref()
const initData = reactive({
  titleVisible: '',
  formLabels: [
    {
      label: 'keywords',
      title: '关键字',
      placeholder: '请输入关键字以搜索',
      width: '300px'
    },
    {
      title: '活动状态',
      label: 'activity',
      type: 'options',
      options: [],
      placeholder: '请选择',
      width: '300px'
    },
    {
      title: '上下架状态',
      label: 'shelves',
      type: 'options',
      options: [
        {
          label: '已上架',
          value: true
        },
        {
          label: '已下架',
          value: false
        }
      ],
      placeholder: '请选择',
      width: '300px'
    },
    {
      title: '活动时间',
      label: 'time',
      type: 'datePicker',
      width: '500px'
    }
  ],
  fromData: {
    // 表单数据
    keywords: '',
    activity: '',
    shelves: '',
    time: []
  },
  formColumn: 4,
  labelWidth: '90px',
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'title', label: '活动名称(标题)' },
    { prop: 'subtitle', label: '副标题' },
    { prop: 'startTime', label: '开始时间' },
    { prop: 'endTime', label: '结束时间' },
    { prop: 'typeLabel', label: '活动类型' },
    { prop: 'activityStatusLabel', label: '活动状态' },
    { prop: 'enableLabel', label: '上下架状态' },
    { slot: 'action' }
  ],
  fileList: [],
  fileObj: {},
  loadingDialog: false,
  fileDisabled: false,
  createEventVisible: false,
  form: {
    title: '',
    subtitle: '',
    time: [],
    type: '',
    activeStatus: '',
    shelvesStatus: '',
    sort: ''
  },
  formRules: {
    title: [
      {
        required: true,
        message: '请输入活动名称（标题）',
        trigger: 'blur'
      }
    ],
    subtitle: [
      {
        required: true,
        message: '请输入副标题',
        trigger: 'blur'
      }
    ],
    time: [
      {
        required: true,
        message: '请选择',
        trigger: 'change'
      }
    ],
    type: [
      {
        required: true,
        message: '请选择活动类型',
        trigger: 'change'
      }
    ],
    activeStatus: [
      {
        required: true,
        message: '请选择活动状态',
        trigger: 'change'
      }
    ],
    shelvesStatus: [
      {
        required: true,
        message: '请选择上下架状态',
        trigger: 'change'
      }
    ]
  },
  typeList: [],
  activeStatusList: [],
  shelvesStatusList: [
    {
      label: '已上架',
      value: true
    },
    {
      label: '已下架',
      value: false
    }
  ],
  preview: false,
  dialogImageUrl: '',
  setImgShow: true,
  activityId: '',
  activitySortVal: '',
  activityTypeVal: '',
  ossObj: '',
  fullscreenLoading: false,
  fileRow: {}
})
const OSS = require('ali-oss')
onMounted(() => {
  getActivityStatus()
  getActivityType()
  getDataList()
})
let client = null
let client1 = null

// 只能输入数字
const handleInput = () => {
  form.value.sort = form.value.sort.replace(/\D/g, '')
}

// 活动状态
const getActivityStatus = () => {
  constantQuery({ className: 'ActivityStatus' }).then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.value,
          value: item.code
        }
      })
      formLabels.value[1].options = list
      activeStatusList.value = list
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 活动类型
const getActivityType = () => {
  constantQuery({ className: 'ActivityType' }).then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.value,
          value: item.code
        }
      })
      typeList.value = list
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: '',
    activity: '',
    shelves: '',
    time: []
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords !== '') {
    data.keyword = fromData.value.keywords
  }
  if (fromData.value.activity !== '') {
    data.activityStatus = fromData.value.activity
  }
  if (fromData.value.shelves !== '') {
    data.enable = fromData.value.shelves
  }
  if (fromData.value.time !== '') {
    data.startTime = fromData.value.time[0]
    data.endTime = fromData.value.time[1]
  }
  activityQuery(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 排序数据回显
const handleChangeSort = val => {
  if (activityTypeVal.value == val && titleVisible.value == '修改活动') {
    form.value.sort = activitySortVal.value
    return false
  }
  activitySort({ type: val }).then(({ data: res }) => {
    if (res.code == 200) {
      form.value.sort = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 创建活动
const createActivity = () => {
  titleVisible.value = '创建活动'
  form.value = {
    title: '',
    subtitle: '',
    time: [],
    type: '',
    activeStatus: '',
    shelvesStatus: ''
  }
  fileObj.value = {}
  fileList.value = []
  setImgShow.value = true
  createEventVisible.value = true
}

// 创建活动提交
const activeSubmit = async formEl => {
  if (setImgShow.value) {
    if (!formEl) return
    await formEl.validate(valid => {
      if (valid) {
        if (fileObj.value.url == '') {
          ElMessage.warning('请上传图片')
          return false
        }
        const data = {
          title: form.value.title,
          subtitle: form.value.subtitle,
          type: form.value.type,
          // homePictureUrl: ossObj.value.dirPath + ossObj.value.fileName,
          homePictureUrl: fileObj.value.url,
          startTime: form.value.time[0],
          endTime: form.value.time[1],
          sort: form.value.sort,
          filePath: ossObj.value.dirPath,
          fileName: ossObj.value.fileName
        }
        if (titleVisible.value == '创建活动') {
          data.fileSize = fileList.value[0].size
          data.sourceFileName = fileList.value[0].name
          activityCreate(data).then(({ data: res }) => {
            if (res.code == 200) {
              ElMessage.success(res.msg)
              getDataList()
              close()
            } else {
              ElMessage.error(res.msg)
            }
          })
        } else {
          if (fileObj.value.url == '') {
            ElMessage.warning('请上传图片')
            return false
          }
          data.id = activityId.value
          data.homePictureUrl = fileObj.value.url
          data.fileSize = fileRow.value.fileSize
          data.sourceFileName = fileRow.value.sourceFileName
          data.filePath = fileRow.value.filePath
          data.fileName = fileRow.value.fileName
          // data.homePictureUrl = fileObj.value.url
          activityUpdate(data).then(({ data: res }) => {
            if (res.code == 200) {
              ElMessage.success(res.msg)
              getDataList()
              close()
            } else {
              ElMessage.error(res.msg)
            }
          })
        }
      }
    })
  } else {
    if (fileObj.value.url == '') {
      ElMessage.warning('请上传图片')
      return false
    }
    // 设置封面图
    const data = {
      id: activityId.value,
      // homePictureUrl: fileRow.value.homePictureUrl,
      homePictureUrl: fileObj.value.url,
      fileSize: fileRow.value.fileSize,
      sourceFileName: fileRow.value.sourceFileName,
      filePath: fileRow.value.filePath,
      fileName: fileRow.value.fileName
    }
    activityPicture(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        getDataList()
        close()
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}

// 创建活动弹窗关闭
const close = () => {
  form.value = {
    title: '',
    subtitle: '',
    time: [],
    type: '',
    activeStatus: '',
    shelvesStatus: ''
  }
  fileObj.value = {}
  fileList.value = []
  createEventVisible.value = false
  dialogForm.value.resetFields()
}

// 下架活动
const takeItem = row => {
  ElMessageBox.confirm(
    '下架后，该活动将在小程序中不可见。是否继续？',
    '提示：',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  )
    .then(() => {
      activityEnable({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 上架活动
const putItem = row => {
  ElMessageBox.confirm('上架后，该活动将在小程序展示。是否继续？', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      activityEnable({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 修改
const editItem = row => {
  activityId.value = row.id
  form.value = {
    title: row.title,
    subtitle: row.subtitle,
    time: [row.startTime, row.endTime],
    type: row.type,
    activeStatus: row.activityStatus,
    shelvesStatus: row.enable,
    sort: row.sort
  }
  fileRow.value = row
  activitySortVal.value = row.sort
  activityTypeVal.value = row.type
  // getOss(row.homePictureUrl)
  fileObj.value.url = row.homePictureUrl
  titleVisible.value = '修改活动'
  setImgShow.value = true
  createEventVisible.value = true
}

// 查看封面图
const lookItem = row => {
  dialogImageUrl.value = row.homePictureUrl
  fileObj.value.url = row.homePictureUrl
  // getOss(row.homePictureUrl)
  preview.value = true
}

// 设置封面图
const setItem = row => {
  activityId.value = row.id
  // getOss(row.homePictureUrl)
  fileObj.value.url = row.homePictureUrl
  fileRow.value = row
  titleVisible.value = '设置封面图'
  setImgShow.value = false
  createEventVisible.value = true
}

// 删除
const deleteItem = row => {
  ElMessageBox.confirm('是否确认删除（该操作不可逆），是否继续？', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      activityDelete({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 文件超出个数限制时的钩子
const exceedFile = (files, fileList) => {
  ElMessage.warning(
    `只能选择1个文件，当前共选择了 ${files.length + fileList.length} 个`
  )
}

// 文件状态改变时的钩子
const fileChange = file => {
  fileList.value.push(file.raw)
}

// 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
const beforeUploadFile = file => {
  let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
  // let size = file.size / 1024 / 1024;
  if (extension != 'png' && extension != 'jpg' && extension != 'jpeg') {
    ElMessage.warning('只能上传后缀是png、jpg、jpeg的文件')
  }
  // if (size > 30) {
  //   ElMessage.warning("文件大小不得超过30M");
  // }
}

const handleRemove = () => {
  fileList.value = []
  fileObj.value.url = ''
}

const UploadUrl = () => {
  // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
  return ''
}

// 上传
const uploadFile = option => {
  // 获取文件的后缀名
  fullscreenLoading.value = true
  putObject({ fileName: option.file.name }).then(({ data: res }) => {
    if (res.code == 200) {
      ossObj.value = res.data
      if (
        titleVisible.value != '修改活动' ||
        titleVisible.value != '设置封面图'
      ) {
        // fileRow.value.homePictureUrl =
        //   ossObj.value.dirPath + ossObj.value.fileName
        fileRow.value.fileSize = option.file.size
        fileRow.value.sourceFileName = option.file.name
        fileRow.value.filePath = ossObj.value.dirPath
        fileRow.value.fileName = ossObj.value.fileName
      }
      client = new OSS({
        region: res.data.regionId,
        secure: true, // secure: 配合region使用，如果指定了secure为true，则使用HTTPS访问
        accessKeyId: res.data.accessKeyId,
        accessKeySecret: res.data.accessKeySecret,
        stsToken: res.data.securityToken,
        bucket: res.data.bucketName,
        cname: true,
        endpoint: `https://${res.data.cdnDomain}`
      })
      put(`${res.data.dirPath + res.data.fileName}`, option.file).then(
        ({ res: data }) => {
          fullscreenLoading.value = false
          if (data.status == 200) {
            fileObj.value.url = data.requestUrls[0]
            // getOss(res.data.dirPath + res.data.fileName)
          } else {
            ElMessage.warning('上传失败')
          }
        }
      )
    }
  })
}
// 获取oss信息
const getOss = urls => {
  ossObject().then(({ data: res }) => {
    if (res.code == 200) {
      client1 = new OSS({
        region: res.data.regionId,
        secure: true, // secure: 配合region使用，如果指定了secure为true，则使用HTTPS访问
        accessKeyId: res.data.accessKeyId,
        accessKeySecret: res.data.accessKeySecret,
        stsToken: res.data.securityToken,
        bucket: res.data.bucketName,
        cname: true,
        endpoint: `https://${res.data.cdnDomain}`
      })
      const response = {
        'Content-Disposition': 'inline'
      }
      // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
      const url = client1.signatureUrl(urls, { response })
      if (url != '') {
        if (preview.value) {
          dialogImageUrl.value = url
          fullscreenLoading.value = false
          return false
        }
        fileObj.value.url = url
        fullscreenLoading.value = false
      }
    }
  })
}

/**
 * @param {string} ObjName OSS的储存路径和文件名字
 * @param {string} fileUrl 本地文件
 * @retruns Promise
 */

const put = async (ObjName, fileUrl) => {
  try {
    let result = await client.put(`${ObjName}`, fileUrl)
    // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称
    return result
  } catch (e) {
    console.log(e)
  }
}

const {
  titleVisible,
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  fileList,
  fileObj,
  loadingDialog,
  fileDisabled,
  form,
  formRules,
  createEventVisible,
  typeList,
  activeStatusList,
  preview,
  dialogImageUrl,
  setImgShow,
  activityId,
  activitySortVal,
  activityTypeVal,
  ossObj,
  fullscreenLoading,
  fileRow
} = toRefs(initData)
</script>
<style lang="scss" scoped>
::v-deep .dialogForm {
  margin-left: 50px;
  .el-form-item__label {
    font-weight: bold;
  }
  .upload-demo {
    width: 360px;
  }
  .box-main {
    position: relative;
    .el-icon {
      position: absolute;
      right: -20px;
      top: 10px;
      cursor: pointer;
    }
  }
}
</style>
